import { createSlice } from '@reduxjs/toolkit';
// import {} from './authApi';
import { STATUS } from '../../constants/fetchStatus';

const initialUser = {
  userId: null,
  pdsNumber: '',
  userName: '',
  email: '',
  phone: '',
  type: '',
  fullNameBangla: '',
  fullNameEnglish: '',
  designationEnglish: '',
  designationBangla: '',
  activeJurisdiction: {
    dutyId: '',
    districtId: '',
    upazillaId: '',
    zoneId: '',
    jurisdictionLevel: '',
    officeOrInstituteId: '',
    workstationId: '',
    inspectorId: '',
    workstationNameBangla: '',
    workstationNameEnglish: '',
    path: '',
    roleNameEnglish: '',
    roleNameBangla: '',
    dutyNameBangla: '',
    dutyNameEnglish: '',
    roleId: 0,
    isPrimary: false,
    permissions: [],
  },
  jurisdictions: [],
};

const initialState = {
  token: null,
  expiresAt: Date.now(),
  refreshBefore: Date.now(),
  error: null,
  user: initialUser,

  isLoggedIn: false,
  status: STATUS.IDLE,
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    resetAuth(state) {
      Object.assign(state, initialState);
    },

    successfulLogin(state, action) {
      const { user, token, refreshBefore, expiresAt } = action.payload;

      state.user = user;
      state.error = null;
      state.token = token;
      state.isLoggedIn = true;
      state.expiresAt = expiresAt;
      state.status = STATUS.SUCCESS;
      state.refreshBefore = refreshBefore;
    },
  },
  //   extraReducers: (builder) => {},
});

export const { resetAuth, successfulLogin } = authSlice.actions;

//selector
export const authInfo = (state) => state.auth;

export default authSlice.reducer;

import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { router } from './routes';
import { generalReducer, updateActiveNavItem } from './redux/generalSlice';
import useNetworkStatus from './hooks/useNetworkStatus';
import { NetworkError } from './components';

function App() {
  const { i18n } = useTranslation();
  const { appLanguage } = useSelector(generalReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(appLanguage);
  }, [i18n, appLanguage]);

  useEffect(() => {
    dispatch(updateActiveNavItem(null));
  }, [dispatch]);

  const isOnline = useNetworkStatus();

  return isOnline ? <RouterProvider router={router} /> : <NetworkError />;
}

export default App;

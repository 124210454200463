import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import {
  formStatus,
  formType,
  NewQuestionArg,
  OptionRemoveArg,
  OptionUpdateArg,
  Question,
  QuestionRemoveArg,
  QuestionUpdateArg,
} from './type';

import {
  addOption,
  addQuestion,
  findQuestion,
  getNewQuestion,
  removeOptionFromSet,
  removeQuestionFromSet,
  updateOptionText,
  updateQuestionFields,
} from './formUtils';

export interface FormDataType {
  nameBangla: string;
  nameEnglish: string;
  hash?: string;
  formJson?: string;
  formType?: formType;
  status?: formStatus;
  questions: Question[];
}

const initialState: FormDataType = {
  nameBangla: '',
  nameEnglish: '',
  hash: '',
  formJson: '[]',
  formType: 'DYNAMIC',
  status: 'ACTIVE',
  questions: [],
};

const formSlice = createSlice({
  name: 'formSlice',
  initialState: initialState,
  reducers: {
    addNewQuestion(state, action: PayloadAction<NewQuestionArg>) {
      const { type, isComputed, parentQuestionId, newQuestion } = action.payload;
      let questionToAdd: Question;
      if (!newQuestion) {
        questionToAdd = getNewQuestion(type, isComputed);
      } else {
        questionToAdd = newQuestion;
      }
      const parentQuestion = findQuestion(state.questions, parentQuestionId);
      if (parentQuestion != null) {
        const newQuestionSet = addQuestion(state.questions, parentQuestionId, questionToAdd);
        state.questions = newQuestionSet;
      } else {
        state.questions.push(questionToAdd);
      }
    },

    addNewOption(state, action: PayloadAction<{ questionId: number }>) {
      const { questionId } = action.payload;
      const newOption = { textBangla: '', textEnglish: '', value: '1', id: Date.now() };
      const question = findQuestion(state.questions, questionId);
      if (question != null) {
        const newQuestionSet = addOption(state.questions, questionId, newOption);
        state.questions = newQuestionSet;
      }
    },

    updateForm(state, action: PayloadAction<FormDataType>) {
      const { nameBangla, nameEnglish, hash, formJson, formType, questions, status } =
        action.payload;
      state.nameBangla = nameBangla;
      state.nameEnglish = nameEnglish;
      state.hash = hash;
      state.formJson = formJson;
      state.formType = formType;
      state.status = status;
      state.questions = questions;
    },

    updateFormTitle(state, action: PayloadAction<string>) {
      state.nameBangla = action.payload;
      state.nameEnglish = action.payload;
    },

    updateQuestion(state, action: PayloadAction<QuestionUpdateArg>) {
      const { questionId, updates } = action.payload;
      state.questions = updateQuestionFields(state.questions, questionId, updates);
    },

    updateOption(state, action: PayloadAction<OptionUpdateArg>) {
      state.questions = updateOptionText(state.questions, action.payload);
    },

    removeQuestion(state, action: PayloadAction<QuestionRemoveArg>) {
      const questionSetCopy: Question[] = [...state.questions];
      const newQuestionSet = removeQuestionFromSet(questionSetCopy, action.payload.questionId);
      state.questions = newQuestionSet;
    },

    removeOption(state, action: PayloadAction<OptionRemoveArg>) {
      const questionSetCopy: Question[] = [...state.questions];

      const newQuestionSet = removeOptionFromSet(questionSetCopy, action.payload);
      state.questions = newQuestionSet;
    },
  },
});

export const {
  updateForm,
  removeQuestion,
  addNewQuestion,
  addNewOption,
  updateFormTitle,
  updateOption,
  updateQuestion,
  removeOption,
} = formSlice.actions;

export const formData = (state: RootState) => state.FormData;

export default formSlice.reducer;

import React, { ReactNode } from 'react';
import { SplitButton, Dropdown } from 'react-bootstrap';

export type SplitItemType = {
  value: string;
  label: string;
};

interface SplitButtonProps {
  className?: string;
  variant?: string;
  options: SplitItemType[];
  onSelect: (key: string) => void;
  onClick?: () => void;
  disabled?: boolean;
  children: ReactNode;
}

const AppSplitButton: React.FC<SplitButtonProps> = ({
  variant = 'primary',
  className,
  options,
  onSelect,
  onClick,
  children,
  disabled = false,
}) => {
  return (
    <>
      <SplitButton
        className={className}
        title={children}
        variant={variant}
        onClick={onClick}
        disabled={disabled}
      >
        {options.map((option) => (
          <Dropdown.Item key={option.value} onClick={() => onSelect(option.value)}>
            {option.label}
          </Dropdown.Item>
        ))}
      </SplitButton>
    </>
  );
};

export default AppSplitButton;

import React from 'react';
import { Pagination } from 'react-bootstrap';
import { digitConversion, en2BnConversion } from '../../utils/helperFunction';
import { TypeSafeDropdown } from '..';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducer } from '../../redux/generalSlice';

interface PaginationProps {
  currentPage: number;
  totalItems: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

const AppPagination: React.FC<PaginationProps> = ({
  currentPage,
  totalItems,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  const tCommon = useTranslation().t;
  const { appLanguage } = useSelector(generalReducer);

  const totalPages = Math.ceil(totalItems / pageSize);

  const getPageSizeItems = () => {
    const options = [
      { labelEn: '50', labelBn: '৫০', value: 50 },
      { labelEn: '75', labelBn: '৭৫', value: 75 },
      { labelEn: '100', labelBn: '১০০', value: 100 },
    ];
    return options.map((option) => ({
      label: en2BnConversion(option.labelBn, option.labelEn),
      value: option.value,
    }));
  };

  const handlePageClick = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const handlePageSizeChange = (pageSize: number) => {
    onPageSizeChange(pageSize);
  };

  const getPageValueText = () => {
    const startValue = digitConversion((currentPage - 1) * pageSize + 1, appLanguage);
    const endValue = digitConversion(Math.min(currentPage * pageSize, totalItems), appLanguage);

    const totalItem = digitConversion(totalItems, appLanguage);

    return tCommon('paginationBodyText', { totalItem, startValue, endValue });
  };

  const renderPaginationItems = () => {
    const items: JSX.Element[] = [];
    const maxItems = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxItems / 2));
    const endPage = Math.min(totalPages, startPage + maxItems - 1);

    if (endPage - startPage + 1 < maxItems) {
      startPage = Math.max(1, endPage - maxItems + 1);
    }

    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => {
            if (page !== currentPage) {
              handlePageClick(page);
            }
          }}
        >
          {page}
        </Pagination.Item>,
      );
    }

    return items;
  };

  return (
    <div className='d-flex align-items-center justify-content-between gap-3'>
      <div>
        <TypeSafeDropdown
          title={tCommon('pageSize')}
          disabledTitle
          value={pageSize}
          selectItems={getPageSizeItems()}
          handleChange={(value) => handlePageSizeChange(+value)}
        />
      </div>
      <div>{getPageValueText()}</div>
      <div>
        <Pagination className='my-0'>
          <Pagination.Prev
            onClick={() => handlePageClick(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {renderPaginationItems()}
          <Pagination.Next
            onClick={() => handlePageClick(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default AppPagination;

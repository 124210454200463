/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  log_icon,
  report_icon,
  settings_icon,
  dashboard_icon,
  survey_leftbar_icon,
  monitoring_plan_leftbar_icon,
  holiday_leftbar_icon,
  institute_list_leftbar_icon,
  monitorings_leftbar_icon,
  help_center_leftbar_icon,
  announcement_leftbar_icon,
  officer_list_leftbar_icon,
} from '../../assets/index';
import { appRoutes } from '../../constants/appRoutes';

let ZONE_ID = process.env.REACT_APP_DASHBOARD_ISAS_CAT_LIST_ZONE_ID || 188;

let store: any;

export const injectStoreToLayout = (_store: any) => {
  store = _store;
};

export const PERMISSION_SET = {
  MANAGE_PLAN_SESSION: 'MANAGE_PLAN_SESSION',
  SUBMIT_VISIT_PLAN: 'SUBMIT_VISIT_PLAN',
  SUPERVISE_SUBMITTED_PLAN: 'SUPERVISE_SUBMITTED_PLAN',
  VIEW_SUBMITTED_PLAN: 'VIEW_SUBMITTED_PLAN',

  VISIT_INSTITUTE: 'VISIT_INSTITUTE',
  VISIT_OFFICE: 'VISIT_OFFICE',
  MANAGE_SUBMITTED_VISIT: 'MANAGE_SUBMITTED_VISIT',

  VIEW_OFFICER_DETAIL: 'VIEW_OFFICER_DETAIL',
  VIEW_INSTITUTE_DETAIL: 'VIEW_INSTITUTE_DETAIL',

  MANAGE_HOLIDAY: 'MANAGE_HOLIDAY',
  MANAGE_AUDIT: 'MANAGE_AUDIT',
  CONTACT_MODULE: 'CONTACT_MODULE',
  EXTRA_JURISDICTION: 'EXTRA_JURISDICTION',
  MANAGE_NOTICE: 'MANAGE_NOTICE',
  VIEW_BI: 'VIEW_BI',
  VIEW_REPORT: 'VIEW_REPORT',
  NONE: 'NONE',
  MANAGE_FORMS: 'MANAGE_FORMS',
  MANAGE_SYSTEM_SETTINGS: 'MANAGE_SYSTEM_SETTINGS',
} as const;

export const LEFT_MENU_KEYS = {
  DASHBOARD: 'DASHBOARD',
  PLAN_SESSION_SETTINGS: 'PLAN_SESSION_SETTINGS',
  VISIT_PLAN: 'VISIT_PLAN',
  MY_PLAN: 'MY_PLAN',
  YEARLY_PLAN_SUBMISSION_STATS: 'YEARLY_PLAN_SUBMISSION_STATS',
  YEARLY_PLAN_SUBMISSION_ISAS_WISE: 'YEARLY_PLAN_SUBMISSION_ISAS_WISE',
  VISIT_REPORT: 'VISIT_REPORT',
  HOLIDAY: 'HOLIDAY',
  MONITORINGS: 'MONITORINGS',
  FORM_MANAGEMENT: 'FORM_MANAGEMENT',
  DYNAMIC_SURVEY_MANAGEMENT: 'DYNAMIC_SURVEY_MANAGEMENT',
  CLASSROOM_MONITORING: 'CLASSROOM_MONITORING',
  INSTITUTIONAL_MONITORING: 'INSTITUTIONAL_MONITORING',
  OFFICE_MONITORING: 'OFFICE_MONITORING',
  INSTITUTE_LIST: 'INSTITUTE_LIST',
  OFFICE_LIST: 'OFFICE_LIST',
  MONITORING_BI: 'MONITORING_BI',
  REPORT: 'REPORT',
  INSTITUTION_REPORT: 'INSTITUTION_REPORT',
  OFFICE_REPORT: 'OFFICE_REPORT',
  CURRICULUM_REPORT: 'CURRICULUM_REPORT',
  OTHERS_REPORT: 'OTHERS_REPORT',
  DYNAMIC_SURVEY_REPORT: 'DYNAMIC_SURVEY_REPORT',
  SURVEY_DATA_COLLECTION: 'SURVEY_DATA_COLLECTION',
  NOTICE: 'NOTICE',
  NOTICE_MANAGEMENT: 'NOTICE_MANAGEMENT',
  NOTICE_LIST: 'NOTICE_LIST',
  AUDIT_LOG: 'AUDIT_LOG',
  SETTINGS: 'SETTINGS',
  HELP_CENTER: 'HELP_CENTER',
} as const;

export interface MenuItem {
  icon: string | null;
  labelEn: string;
  labelBn: string;
  children: MenuItem[];
  isCollapsible: boolean;
  key: keyof typeof LEFT_MENU_KEYS;
  requiredPermission: Array<keyof typeof PERMISSION_SET>;
  requiredAnyPermission: Array<keyof typeof PERMISSION_SET>;
  navigate: string | null;
}
const getLeftbarItems = (zoneId: number): MenuItem[] => {
  return [
    {
      key: LEFT_MENU_KEYS.DASHBOARD,
      icon: dashboard_icon,
      labelBn: 'ড্যাশবোর্ড',
      labelEn: 'Dashboard',
      children: [],
      isCollapsible: false,
      requiredPermission: [],
      requiredAnyPermission: [],
      navigate: appRoutes.dashboard,
    },
    {
      key: LEFT_MENU_KEYS.PLAN_SESSION_SETTINGS,
      icon: settings_icon,
      labelBn: 'পরিকল্পনা সেশন সেটিংস',
      labelEn: 'Plan Session Settings',
      children: [],
      isCollapsible: false,
      requiredPermission: [PERMISSION_SET.MANAGE_PLAN_SESSION],
      requiredAnyPermission: [],
      navigate: appRoutes.sessionList,
    },
    {
      key: LEFT_MENU_KEYS.HOLIDAY,
      icon: holiday_leftbar_icon,
      labelBn: 'ছুটির তালিকা',
      labelEn: 'Holiday List',
      children: [],
      isCollapsible: false,
      requiredPermission: [PERMISSION_SET.MANAGE_HOLIDAY],
      requiredAnyPermission: [],
      navigate: appRoutes.holiday(new Date().getFullYear()),
    },
    {
      key: LEFT_MENU_KEYS.VISIT_PLAN,
      icon: monitoring_plan_leftbar_icon,
      labelBn: 'মনিটরিং পরিকল্পনা',
      labelEn: 'Monitoring Plan',
      isCollapsible: true,
      children: [
        {
          key: LEFT_MENU_KEYS.MY_PLAN,
          icon: null,
          labelBn: 'আমার পরিকল্পনা',
          labelEn: 'My Plan',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [],
          requiredPermission: [PERMISSION_SET.SUBMIT_VISIT_PLAN],
          navigate: appRoutes.myPlan(new Date().getFullYear()),
        },
        {
          key: LEFT_MENU_KEYS.YEARLY_PLAN_SUBMISSION_STATS,
          icon: null,
          labelBn: 'বার্ষিক পরিকল্পনা জমাদান পরিসংখ্যান',
          labelEn: 'Yearly Plan Submission Statistics',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [],
          requiredPermission: [PERMISSION_SET.VIEW_SUBMITTED_PLAN],
          navigate: appRoutes.submissionStat(new Date().getFullYear()),
        },
        {
          key: LEFT_MENU_KEYS.YEARLY_PLAN_SUBMISSION_ISAS_WISE,
          icon: null,
          labelBn: 'বার্ষিক পরিকল্পনা জমাদান ISAS ক্যাটাগরি অনুযায়ী',
          labelEn: 'Yearly Plan Submission ISAS Category Wise',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [],
          requiredPermission: [PERMISSION_SET.SUPERVISE_SUBMITTED_PLAN],
          navigate: appRoutes.isasComplaintDetails(new Date().getFullYear(), zoneId),
        },
      ],
      requiredPermission: [],
      requiredAnyPermission: [
        PERMISSION_SET.SUBMIT_VISIT_PLAN,
        PERMISSION_SET.SUPERVISE_SUBMITTED_PLAN,
      ],
      navigate: null,
    },
    {
      key: LEFT_MENU_KEYS.MONITORINGS,
      icon: monitorings_leftbar_icon,
      labelBn: 'মনিটরিং সমূহ',
      labelEn: 'Monitorings',
      isCollapsible: true,
      children: [
        {
          key: LEFT_MENU_KEYS.CLASSROOM_MONITORING,
          icon: null,
          labelBn: 'শ্রেণিকক্ষ কার্যক্রম মনিটরিং',
          labelEn: 'Classroom Activities Monitoring',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [
            PERMISSION_SET.MANAGE_SUBMITTED_VISIT,
            PERMISSION_SET.VISIT_INSTITUTE,
          ],
          requiredPermission: [],
          navigate: appRoutes.curriculumVisits,
        },
        {
          key: LEFT_MENU_KEYS.INSTITUTIONAL_MONITORING,
          icon: null,
          labelBn: 'সাধারণ মনিটরিং',
          labelEn: 'General Monitoring',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [
            PERMISSION_SET.MANAGE_SUBMITTED_VISIT,
            PERMISSION_SET.VISIT_INSTITUTE,
          ],
          requiredPermission: [],
          navigate: appRoutes.generalVisits,
        },
        {
          key: LEFT_MENU_KEYS.OFFICE_MONITORING,
          icon: null,
          labelBn: 'অফিস মনিটরিং',
          labelEn: 'Office Monitoring',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [
            PERMISSION_SET.MANAGE_SUBMITTED_VISIT,
            PERMISSION_SET.VISIT_OFFICE,
          ],
          requiredPermission: [],
          navigate: appRoutes.officeVisits,
        },
      ],
      requiredPermission: [],
      requiredAnyPermission: [],
      navigate: null,
    },
    {
      key: LEFT_MENU_KEYS.FORM_MANAGEMENT,
      icon: survey_leftbar_icon,
      labelBn: 'ফর্ম',
      labelEn: 'Form',
      isCollapsible: false,
      children: [],
      requiredPermission: [PERMISSION_SET.MANAGE_FORMS],
      requiredAnyPermission: [],
      navigate: appRoutes.manageForm,
    },
    // {
    //   key: LEFT_MENU_KEYS.DYNAMIC_SURVEY_MANAGEMENT,
    //   icon: help_center_leftbar_icon,
    //   labelBn: 'গতিশীল জরিপ ব্যবস্থাপনা',
    //   labelEn: 'Dynamic Survey Management',
    //   isCollapsible: false,
    //   children: [],
    //   requiredPermission: [PERMISSION_SET.MANAGE_FORMS],
    //   requiredAnyPermission: [],
    //   navigate: appRoutes.dynamicSurveyManagement,
    // },
    {
      key: LEFT_MENU_KEYS.MONITORING_BI,
      icon: survey_leftbar_icon,
      labelBn: 'মনিটরিং পরিসংখ্যান',
      labelEn: 'Monitoring BI',
      isCollapsible: false,
      children: [],
      requiredPermission: [PERMISSION_SET.VIEW_BI],
      requiredAnyPermission: [],
      navigate: appRoutes.monitoringBI,
    },
    {
      key: LEFT_MENU_KEYS.REPORT,
      icon: report_icon,
      labelBn: 'রিপোর্ট',
      labelEn: 'Report',
      isCollapsible: true,
      children: [
        {
          key: LEFT_MENU_KEYS.INSTITUTION_REPORT,
          icon: null,
          labelBn: 'প্রতিষ্ঠান',
          labelEn: 'Institution',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [],
          requiredPermission: [PERMISSION_SET.VIEW_REPORT],
          navigate: appRoutes.institutionReport,
        },
        {
          key: LEFT_MENU_KEYS.OFFICE_REPORT,
          icon: null,
          labelBn: 'অফিস',
          labelEn: 'Office',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [],
          requiredPermission: [PERMISSION_SET.VIEW_REPORT],
          navigate: appRoutes.officeReport,
        },
        {
          key: LEFT_MENU_KEYS.OTHERS_REPORT,
          icon: null,
          labelBn: 'অন্যান্য',
          labelEn: 'Others',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [],
          requiredPermission: [PERMISSION_SET.VIEW_REPORT],
          navigate: appRoutes.othersReport,
        },
        // {
        //   key: LEFT_MENU_KEYS.DYNAMIC_SURVEY_REPORT,
        //   icon: null,
        //   labelBn: 'জরিপ',
        //   labelEn: 'Survey',
        //   isCollapsible: false,
        //   children: [],
        //   requiredAnyPermission: [],
        //   requiredPermission: [PERMISSION_SET.VIEW_REPORT],
        //   navigate: appRoutes.dynamicSurveyReport,
        // },
      ],
      requiredPermission: [PERMISSION_SET.VIEW_REPORT],
      requiredAnyPermission: [],
      navigate: null,
    },
    {
      key: LEFT_MENU_KEYS.INSTITUTE_LIST,
      icon: institute_list_leftbar_icon,
      labelBn: 'প্রতিষ্ঠান তালিকা',
      labelEn: 'Institute List',
      isCollapsible: false,
      children: [],
      requiredPermission: [PERMISSION_SET.VIEW_INSTITUTE_DETAIL],
      requiredAnyPermission: [],
      navigate: appRoutes.instituteList,
    },
    {
      key: LEFT_MENU_KEYS.OFFICE_LIST,
      icon: officer_list_leftbar_icon,
      labelBn: 'অফিসার তালিকা',
      labelEn: 'Officer List',
      isCollapsible: false,
      children: [],
      requiredPermission: [PERMISSION_SET.VIEW_OFFICER_DETAIL],
      requiredAnyPermission: [],
      navigate: appRoutes.officerList,
    },
    {
      key: LEFT_MENU_KEYS.NOTICE,
      icon: announcement_leftbar_icon,
      labelBn: 'বিজ্ঞপ্তি',
      labelEn: 'Notice',
      isCollapsible: true,
      children: [
        {
          key: LEFT_MENU_KEYS.NOTICE_LIST,
          icon: null,
          labelBn: 'বিজ্ঞপ্তির তালিকা',
          labelEn: 'Notice List',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [],
          requiredPermission: [],
          navigate: appRoutes.noticeList,
        },
        {
          key: LEFT_MENU_KEYS.NOTICE_MANAGEMENT,
          icon: null,
          labelBn: 'বিজ্ঞপ্তি ব্যবস্থাপনা',
          labelEn: 'Notice Management',
          isCollapsible: false,
          children: [],
          requiredAnyPermission: [],
          requiredPermission: [PERMISSION_SET.MANAGE_NOTICE],
          navigate: appRoutes.noticeManagement,
        },
      ],
      requiredPermission: [],
      requiredAnyPermission: [],
      navigate: null,
    },
    {
      key: LEFT_MENU_KEYS.SETTINGS,
      icon: settings_icon,
      labelBn: 'সেটিংস',
      labelEn: 'Settings',
      isCollapsible: false,
      children: [],
      requiredPermission: [PERMISSION_SET.MANAGE_SYSTEM_SETTINGS],
      requiredAnyPermission: [],
      navigate: appRoutes.settings,
    },
    {
      key: LEFT_MENU_KEYS.AUDIT_LOG,
      icon: log_icon,
      labelBn: 'অডিট লগ',
      labelEn: 'Audit Log',
      isCollapsible: false,
      children: [],
      requiredPermission: [PERMISSION_SET.MANAGE_AUDIT],
      requiredAnyPermission: [],
      navigate: appRoutes.auditLog,
    },
    {
      key: LEFT_MENU_KEYS.HELP_CENTER,
      icon: help_center_leftbar_icon,
      labelBn: 'হেল্প সেন্টার',
      labelEn: 'Help Center',
      isCollapsible: false,
      children: [],
      requiredPermission: [],
      requiredAnyPermission: [],
      navigate: appRoutes.helpCenter,
    },
  ];
};

const hasPermission = (
  userPermissions: string[],
  requiredPermissions: string[],
  requiredAnyOfPermissions: string[],
) => {
  if (!requiredPermissions.length && !requiredAnyOfPermissions.length) {
    return true;
  }

  const hasAllPermission = requiredPermissions.every((permission) =>
    userPermissions.includes(permission),
  );

  if (!requiredAnyOfPermissions.length) {
    return hasAllPermission;
  }

  return requiredAnyOfPermissions.some((permission) => userPermissions.includes(permission));
};

const prepareNavItems = (userPermissions: string[], navItems: MenuItem[], result: MenuItem[]) => {
  navItems.forEach((ele) => {
    const hasRequiredPermissions = hasPermission(
      userPermissions,
      ele.requiredPermission,
      ele.requiredAnyPermission,
    );

    if (ele.isCollapsible) {
      const childrenResult: MenuItem[] = [];
      prepareNavItems(userPermissions, ele.children, childrenResult);

      if (childrenResult.length > 0 || hasRequiredPermissions) {
        result.push({
          ...ele,
          children: childrenResult,
        });
      }
    } else {
      if (hasRequiredPermissions) {
        result.push(ele);
      }
    }
  });

  return result;
};

export const getUserNavItems = (userPermissions: string[]) => {
  const userNavItems: MenuItem[] = [];

  const getActiveJurisdiction = () => {
    const { activeJurisdiction } = store.getState().auth.user;
    return activeJurisdiction;
  };

  const activeJurisdiction = getActiveJurisdiction();

  if (activeJurisdiction.zoneId) {
    ZONE_ID = activeJurisdiction.zoneId;
  }

  getLeftbarItems(+ZONE_ID).forEach((item: MenuItem) => {
    if (hasPermission(userPermissions, item.requiredPermission, item.requiredAnyPermission)) {
      if (item.isCollapsible) {
        const result = prepareNavItems(userPermissions, item.children, []);
        if (result.length > 0) {
          userNavItems.push({
            ...item,
            children: result,
          });
        }
      } else {
        userNavItems.push(item);
      }
    }
  });

  return userNavItems;
};

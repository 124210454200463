import HttpInstance from '../../configs/axiosClient';
import { apiRoutes } from '../../constants/apiRoutes';
import { authApiRoutes } from '../../constants/apiRoutes/authApiRoutes';

export const switchUserDuty = (dutyId) => {
  return HttpInstance.get(apiRoutes.switchUserDuty(dutyId));
};

export const guestLogin = (data) => {
  return HttpInstance.post(authApiRoutes.guestLogin, data);
};

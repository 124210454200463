import { combineReducers } from '@reduxjs/toolkit';
import { logout } from '../services/logoutService';
import authSlice from '../pages/auth/authSlice';
import generalSlice from './generalSlice';
import locationSlice from './locationSlice';
import NotificationSlice from '../pages/notice/NotificationSlice';
import FormSlice from '../pages/form/formSlice';
import { LEFT_MENU_KEYS } from '../components/layout/layoutUtils';
import { appLanguages } from '../constants/appConstants';

const combinedReducer = combineReducers({
  auth: authSlice,
  general: generalSlice,
  allLocation: locationSlice,
  userNotification: NotificationSlice,
  FormData: FormSlice,
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = {
      ...state,
      auth: undefined,
      general: {
        ...state.general,
        appLanguage: appLanguages.Bengali,
        activeNavItem: LEFT_MENU_KEYS.DASHBOARD,
        homePageMessage: state.general.homePageMessage,
      },
    };
  }

  return combinedReducer(state, action);
};

export default rootReducer;

import React, { ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';
import { PERMISSION_SET } from "../layout/layoutUtils";
import PermissionWrapper from './PermissionWrapper';

interface ButtonProps {
  onClick: () => void;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  requirePermission?: keyof typeof PERMISSION_SET;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  className,
  children,
  disabled,
  loading,
  requirePermission=PERMISSION_SET.NONE,
  ...rest
}) => {
    const btn= (
    <button
      className={`${disabled && 'disable-btn'} app-btn ${className} `}
      onClick={onClick}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <>
          {children} <Spinner size='sm' />
        </>
      ) : (
        children
      )}
    </button>
  );

  if(requirePermission != PERMISSION_SET.NONE){
    return (
      <PermissionWrapper permissionName={requirePermission} fallback={<></>}>
        {btn}
      </PermissionWrapper>
    )
  }

  return btn;
};

export default Button;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { localizationNS } from '../../constants/appConstants';
import { useNavigate } from 'react-router-dom';

const PreviousPageButton = () => {
  const tCommon = useTranslation(localizationNS.common).t;
  const navigate = useNavigate();
  return (
    <div className='my-3'>
      <span onClick={() => navigate(-1)} className='previous-page'>
        {tCommon('seePreviousPage')}
      </span>
    </div>
  );
};

export default PreviousPageButton;
